import React, { useState, useEffect } from 'react';
import { DataGrid,GridToolbar,GridToolbarContainer,GridToolbarExport } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import config from "./../config";
import CircularProgress from '@material-ui/core/CircularProgress';
import './GenericDataGrid.css';

const useStyles = makeStyles((theme) => ({
  
    root: {
        '& .super--cell': {
          backgroundColor: 'rgba(224, 183, 60, 0.55)',
          color: '#1a3e72',
          fontWeight: '600',
        },
        '& .super--cell2': {
          color: 'black',
          textAlign:'center',
        },
        '& .super-app-theme--header': {
          fontSize:'1.1rem',
        },
      },
    col:{
        color:'black',
    },
    title:{
      fontFamily:'Barlow, sans-serif',
      fontSize:'1.6rem',
      textAlign:'center',
      marginTop:'10px',
    marginBottom:'45px'
  },
  load:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
},
cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'180px',
},
box:{
    borderBottomStyle:'solid',
    borderWidth:'1px',
    borderColor:'#E2E2E2',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
}
  }));

const DataGridsts = () => {
    const classes = useStyles();
    function ucfirst(str) {
        var firstLetter = str.substr(0, 1);
        return firstLetter.toUpperCase() + str.substr(1);
    }
    const capital = {
        valueFormatter: ({ value }) => ucfirst(String(value)),
      };
    const columns=[
        {
            field: 'action',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
            renderCell: (params) => <Link to={`/editsts/${params.row.CUSTOMER_ID}`}><EditIcon className={classes.col}/></Link>
          },
          { field: 'CUSTOMER_ID', headerName: 'Customer ID', width:130,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'UPLOAD_APPROVED', headerName: 'Upload Approved', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'DOCUMENTS_EXTRACTED', headerName: 'Documents Extracted', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'DOCUMENT_COMMENTS', headerName: 'Document Comments', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'CODED', headerName: 'Coded', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'QUALITY_REVIEWED', headerName: 'Quality Reviewed', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'QUALITY_COMMENTS', headerName: 'Quality Comments', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'CODING_CYCLE', headerName: 'Coding Cycle', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'CODING_ELIGIBLE', headerName: 'Coding Eligible', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'CODING_ISSUE', headerName: 'Coding Issue', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'WILDFIRE_ACTION', headerName: 'Wildfire Action', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'DISTANCE_TO_RELEVANT_WILDFIRE_BAND', headerName: 'Distance to Wildfire Band', width:150,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'WILDFIRE_RESULT', headerName: 'Wildfire Result', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'CONSTRUCTION_TIV_THRESHOLD', headerName: 'Construction TIV Threshold', width:150,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'OTHER_UW_ELIGIBILITY', headerName: 'Other UW Eligibility', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'OTHER_UW_ELIGIBILITY_COMMENTS', headerName: 'Other UW Eligibility Comments', width:190,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'LOSS_RUNS', headerName: 'Loss Runs', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'LOSS_RUNS_COMMENTS', headerName: 'Loss Runs Comments', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'UW_COMMENTS', headerName: 'UW Comments', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'UW_ACTION_PENDING', headerName: 'UW Action Pending', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'UW_ACTION', headerName: 'UW Action', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'DATA_UPDATES_REQUIRED', headerName: 'Data Updates Required', width:150,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'DATA_UPDATED_COMPLETED', headerName: 'Data Updates Completed', width:150,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'DATA_COMMENTS', headerName: 'Data Comments', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'REFERRAL_TO_UW', headerName: 'Referral to UW', width:130,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'REFERRAL_TO_UW_COMMENTS', headerName: 'Referral to UW Comments', width:150,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'UW_DECISION', headerName: 'UW Decision', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'US_ISSUE', headerName: 'US Issue', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'RATING_STATUS', headerName: 'Rating Status', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'BROKER_STATUS', headerName: 'Broker Status', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'BROKER_REMOVAL_REASON', headerName: 'Broker Removal Reason', width:170,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'UW_PROPOSAL_LOCK', headerName: 'UW Proposal Lock', width:140,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'UW_BIND_LOCK', headerName: 'UW Bind Lock', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'QUOTE_LOSS_REASON', headerName: 'Quote Loss Reason', width:140,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'FAC_REQUIRED', headerName: 'FAC Required', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'FAC_ACQUIRED', headerName: 'FAC Acquired', width:120,cellClassName: 'super--cell2',headerAlign: 'center',...capital},
          { field: 'FAC_REINSURER', headerName: 'FAC Reinsurer', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'FAC_CERTIFICATE_NUMBER', headerName: 'FAC Certificate Number', width:150,cellClassName: 'super--cell2',headerAlign: 'center'},
          { field: 'UPLOAD_COMMENTS', headerName: 'Upload Comments', width:120,cellClassName: 'super--cell2',headerAlign: 'center'},
        ];
    const [dataRows, setDataRows ] = useState([]);
    const [loading, setLoading] = useState(true);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    
    function CustomToolbar() {
        return (
          <GridToolbarContainer className={classes.box}>
            <GridToolbarExport />
          </GridToolbarContainer>
        );
      }

    useEffect( () => {
        axios.get(config.server_url + '/sts/')
        .then(response => {
            const newdata = response.data.map((d,index)=> {d.id = index; return d})
            console.log(newdata)
            setDataRows(newdata);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }, [shouldRefresh]);

  const renderLoadingMessage = () => <div className={classes.cent}><CircularProgress color="primary" /></div>;

  const renderGrid = () => (
    <div className={classes.root} style={{ height: 800, width: '100%' }}>
        <DataGrid headerHeight={70} density={'compact'} rows={dataRows} columns={columns} pageSize={100} getRowId={(row)=> row.id } components={{
                Toolbar: CustomToolbar,
              }} />
    </div>
    );

    return (<>
         <h3 className={classes.title}>Customer Status</h3>
        {
            loading ? renderLoadingMessage() : renderGrid()
        }
    </>);
}

DataGridsts.propTypes = {
    columns: PropTypes.array.isRequired,
    pageSize: PropTypes.number.isRequired,
  };

export default DataGridsts