import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from '../components/GenericDataGrid';
import AppBar from './AppBar';
import config from "./../config"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'10px',
    marginBottom:'45px'
},
root: {
  marginTop:'40px',
  '& .super--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
    
  },
  '& .super--cell2': {
    color: 'black',
    textAlign:'center'
    
  },
  '& .super--cell3': {
    color: 'black',
    fontWeight: '600',
    textAlign:'center',
    
  },
  '& .super4--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
  },
  '& .super--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)',
    fontWeight: 'bold',
    flexWrap:'wrap',
    alignItems:'center',
    textAlign:'center',
  },
}
}));

const HomePage = ({data}) => {
  const classes = useStyles();
  console.log(data)

  const [policies, setPolicies] = useState([]);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
  });
  
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell',
  };
  const usdPrice2 = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell3',
  };
  const usdPrice4 = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super4--cell',
  };

  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <GenericDataGrid 
      dataRows={data}
      ID = {'id'}
      ht={800}
      columns={[
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'POOLS_DIVING_BOARDS', headerName: 'Pools Diving Boards', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'POOLS_SLIDES', headerName: 'Pool Slides', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'BEACHES_NUMBER_OF_BEACHES', headerName: 'Number of Beaches', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BOAT_STORAGE_AND_MOORAGE_GROSS_SALES', headerName: 'Boat Storage and Moorage Gross Sales', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'BUILDING_OR_PREMISES_LESSORS_RISK_DESCRIBE_RETAIL_EXPOSURE', headerName: 'Build/Prem Retail Exposure',cellClassName: 'super--cell2',headerAlign: 'center',type:'number', width: 170},
        { field: 'BUILDING_OR_PREMISES_LESSORS_RISK_OTHER_THAN_RETAIL_EXPOSURE', headerName: 'Build/Prem Other Than Retail Exposure', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BUILDING_OR_PREMISES_LESSORS_RISK_SQUARE_FOOTAGE', headerName: 'Build/Prem Square Footage', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CLUBS_SQUARE_FOOTAGE', headerName: 'Clubs Square Footage', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'LAKES_OR_RESERVOIRS_NUMBER_OF_LAKES_OR_RESERVOIRS', headerName: 'Number of Lakes or Reservoirs', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PARKING_PARKING_SQUARE_FOOTAGE', headerName: 'Parking Square Footage', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'PARKS_OR_PLAYGROUNDS_NUMBER_OF_PARKS_INCLUDING_DOG_PARKS', headerName: 'Number of Parks', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'DOG_PARK', headerName: 'Number of Dog Parks', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'STREETS_ROADS_HIGHWAYS_OR_BRIDGES_MILES', headerName: 'Streets Roads Highways or Bridges', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'SWIMMING_POOLS_COMPLIANCE', headerName: 'Swimming Pools Compliance', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'SWIMMING_POOLS_NUMBER_OF_POOLS', headerName: 'Number of Swimming Pools', width: 170,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'WAREHOUSES_SQUARE_FOOTAGE', headerName: 'Warehouses Square Footage', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BEACHES_LIFEGUARDS_PRESENT', headerName: 'Beaches Lifeguards Present', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BEACHES_MARKED_SWIMMING_AREA', headerName: 'Beaches marked Swimming Area', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BEACHES_POSTED_RULES', headerName: 'Beaches Posted Rules', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BOAT_DOCKS_SLIPS_COVERED', headerName: 'Boat Docks Slips Covered', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BOAT_DOCKS_SLIPS_INSPECTED_ANNUALLY', headerName: 'Boat Docks Slips Inspc Annually', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BOAT_DOCKS_SLIPS_NONSLIP_SURFACE', headerName: 'Boat Docks Slips Nonslip Surface', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BOAT_DOCKS_SLIPS_NUMBER', headerName: 'Boat Docks Slips Number', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BOAT_DOCKS_SLIPS_POSTED_RULES', headerName: 'Boat Docks Slips Posted Rules', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PLAYGROUND_AGE_OF_EQUIPMENT', headerName: 'Playground age of Equipment', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PLAYGROUND_REGULAR_INSPECTIONS', headerName: 'Playground Regular Inspections',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'PLAYGROUND_SURFACE', headerName: 'Playground Surface', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PLAYGROUND_SURFACE_DESCRIBE_OTHER', headerName: 'Playground Surface Describe Other', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'POOLS_DESCRIBE_ADDITIONAL_EQUIPMENT', headerName: 'Pool\'s Describe Additional Equipment', width: 190,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'POOLS_LOCATION', headerName: 'Pool\'s Location', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
    ]}
    pageSize={100}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

export default HomePage;