import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from './GenericDataGrid';
import AppBar from './AppBar';
import config from "../config"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'10px',
    marginBottom:'45px'
},
root: {
  marginTop:'40px',
  '& .super--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
    
  },
  '& .super--cell2': {
    color: 'black',
    textAlign:'center'
    
  },
  '& .super--cell3': {
    color: 'black',
    fontWeight: '600',
    textAlign:'center',
    
  },
  '& .super4--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
  },
  '& .super--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)',
    fontWeight: 'bold',
    flexWrap:'wrap',
    alignItems:'center',
    textAlign:'center',
  },
}
}));

const HomePage = ({data}) => {
  const classes = useStyles();
  console.log(data)

  const [policies, setPolicies] = useState([]);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
  });
  
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell',
  };
  const usdPrice2 = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell3',
  };
  const usdPrice4 = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super4--cell',
  };

  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <GenericDataGrid 
      dataRows={data}
      ID = {'id'}
      ht={800}
      columns={[
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'DISCHARGE_WATER_BACKUP_AND_SUMP_OVERFLOW_COVERAGE_INDICATOR', headerName: 'Discharge water backup and sump overflow covg Ind', width: 240,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'DISCHARGE_WATER_BACKUP_PROPERTY_DAMAGE_LIMIT', headerName: 'Discharge water backup PD Limit', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' ,...usdPrice2},
        { field: 'UTILITY_SERVICES_DD_CAUSES_OF_LOSS_FORM_APPLICABLE', headerName: 'Utility Svc DD Causes of Loss Form Applicable', width: 200,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES', headerName: 'Utility Svc DD Comm Supply inc Ovhd Trans Lines', width: 240,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES', headerName: 'Utility Svc DD Comm Supply not inc Ovhd Trans Lines',cellClassName: 'super--cell2',headerAlign: 'center',type:'number', width: 240},
        { field: 'UTILITY_SERVICES_DD_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES', headerName: 'Utility Svc DD Power Supply inc Ovhd Trans Lines', width: 220,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_DD_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES', headerName: 'Utility Svc DD Power Supply not inc Ovhd Trans Lines', width: 220,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_DD_UTILITY_SERVICES_LIMIT', headerName: 'Utility Svc DD Utility Svc Limit', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_DD_WATER_SUPPLY_PROPERTY_COVERAGE_INDICATOR', headerName: 'Utility Svc DD Water Supply Property Covg Ind', width: 220,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_DIRECT_DAMAGE_COVERED_PROPERTY_DESCRIPTION', headerName: 'Utility Svc Direct Damage covd Propert Desc', width: 220 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'UTILITY_SERVICES_TE_CAUSES_OF_LOSS_FORM_APPLICABLE', headerName: 'Utility Svc TE Loss Form Applicable', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES', headerName: 'Utility Svc TE Comm Supply inc Ovhd Trans Lines', width: 220,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES', headerName: 'Utility Svc TE Comm Supply not inc Ovhd Trans Lines', width: 240,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_TE_COVERED_PROPERTY_DESCRIPTION', headerName: 'Utility Svc TE covd Propert Desc', width: 170,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_TE_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES', headerName: 'Utility Svc TE Power Supply inc Ovhd Trans Lines', width: 220,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_TE_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES', headerName: 'Utility Svc TE Comm Supply not inc Ovhd Trans Lines', width: 240,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_TE_UTILITY_SERVICES_LIMIT', headerName: 'Utility Svc TE Utility Svc Limit', width: 160,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_TE_WASTEWATER_REMOVAL_PROPERTY', headerName: 'Utility Svc TE Wastewater Removal Property', width: 220,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UTILITY_SERVICES_TE_WATER_SUPPLY_PROPERTY_COVERAGE', headerName: 'Utility Svc TE Water Supply Property Covg', width: 200,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PREM', headerName: 'Amend Liq Liability Excl Desc Premises Activities Prem', width: 240,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PROD', headerName: 'Amend Liq Liability Excl Desc Premises Activities Prod', width: 240,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PREM_IND', headerName: 'Amend Liq Liability Excl Exception for Premises Ops Prem Ind', width: 270,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PROD_IND', headerName: 'Amend Liq Liability Excl Exception for Premises Ops Prod Ind', width: 270,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BOAT_LIABILITY_COV_IND', headerName: 'Boat Liability Cov Ind', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BOAT_LIABILITY_DESCRIBE_WATERCRAFT', headerName: 'Boat Liability Describe Watercraft', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'EMP_BENEFITS_AGGREGATE_LIMIT', headerName: 'Emp Benefits Agg Limit', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'EMP_BENEFITS_AMOUNT_INSURANCE_EACH_EMP', headerName: 'Emp Benefits Amt Insurance each EMP', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'EMP_BENEFITS_COV_IND', headerName: 'Emp Benefits Cov Ind', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'EMP_BENEFITS_LIABILITY_DEDUCTIBLE_PER_CLAIM', headerName: 'Emp Benefits Liability Deductible Per Claim', width: 200,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'EMP_BENEFITS_NUMBER_EMPS', headerName: 'Emp Benefits Number EMPS', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'EMP_BENEFITS_RETROACTIVE_DATE', headerName: 'Emp Benefits Retroactive Date', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
    ]}
    pageSize={100}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

export default HomePage;