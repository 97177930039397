import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useEffect, useState } from 'react';
import axios from 'axios';
import PolicyHD from './PolicyHD'
import PolicyBD from './PolicyBD'
import PolicyGL from './PolicyGL'
import PolicyCVG from './PolicyCVG'
import PolicyMO from './PolicyMO'
import PolicyEND from './PolicyEND'
import PolicyBN from './PolicyBN'
import PolicyADD from './PolicyADD'
import Bench from './Bench'
import { Link } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import config from "./../config"
import CircularProgress from '@material-ui/core/CircularProgress';


let theme = createMuiTheme({
    palette: {
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    typography: {
      fontFamily: 'Barlow, sans-serif',
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
        fontFamily:'Barlow, sans-serif',
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });
  
  theme = {
    ...theme,
    overrides: {
      MuiDrawer: {
        paper: {
          backgroundColor: 'black',
        },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
      MuiTabs: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: '#4fc3f7',
        },
      },
      MuiTab: {
        
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: theme.spacing(1),
        },
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: 4,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#404854',
        },
      },
      MuiListItemText: {
        primary: {fontSize: 25,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      MuiListItemIcon: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
      MuiAvatar: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({

    font:{
        fontFamily: 'Barlow, sans-serif',
        fontSize: '1.1rem',
        textDecoration: 'none',
        paddingTop:'10px',
        color:'black',
        fontWeight:'bold',
        paddingBottom:'0px',
    },
    col:{
        backgroundColor:'black',
        width:'80%',
        minWidth:'80%',
        display:'flex',
        justifyContent:'center',
        borderRadius:'8px'
    },
    coldiv:{
      display:'flex',
      justifyContent:'center'
    },
    size:{
        fontSize:'1.1rem',
        display:'inline-block'
    },
  root: {
    flexGrow: 1,
    
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    paddingTop:'0px',
    paddingBottom:'0px',
    marginTop: '20px',
    marginBottom: '0px',
},
load:{
  fontFamily:'Barlow, sans-serif',
  fontSize:'1.6rem',
  textAlign:'center',
},
button: {
  backgroundColor: '#F6FBFE',
  fontFamily:'Barlow, sans-serif',
  border: 'none',
  color: 'black',
  padding: '8px 8px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  margin: '8px 8px',
  cursor: 'pointer',
  borderRadius: '8px',
  outline: 0,
  fontWeight:'bold',
},
agn:{
  marginLeft: 'auto',
marginRight: '0'
},
cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'180px',
},
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [isSubmit, setisSubmit] = useState(false);
  const [dataRows, setDataRows ] = useState([]);
  const [dataCpRows, setDataCpRows ] = useState([]);
  const [dataGlRows, setDataGlRows ] = useState([]);
  const [dataCvgRows, setDataCvgRows ] = useState([]);
  const [dataMoRows, setDataMoRows ] = useState([]);
  const [dataEndRows, setDataEndRows ] = useState([]);
  const [dataBnRows, setDataBnRows ] = useState([]);
  const [dataAddRows, setDataAddRows ] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCp, setCpLoading] = useState(true);
  const [loadingGl, setGlLoading] = useState(true);
  const [loadingCvg, setCvgLoading] = useState(true);
  const [loadingMo, setMoLoading] = useState(true);
  const [loadingEnd, setEndLoading] = useState(true);
  const [loadingBn, setBnLoading] = useState(true);
  const [loadingAdd, setAddLoading] = useState(true);
  
useEffect( () => {
  axios.get(config.server_url + '/pt/header')
  .then(response => {
      const newdata = response.data.map((d,index)=> {d.id = index; return d})
      setDataRows(newdata);
      setLoading(false);
  })
  .catch((error) => {
      console.log(error);
  })

  axios.get(config.server_url + '/pt/building')
  .then(response => {
      const newdata = response.data.map((d,index)=> {d.id = index; return d})
      setDataCpRows(newdata);
      setCpLoading(false);
  })
  .catch((error) => {
      console.log(error);
  })

  axios.get(config.server_url + '/pt/gl')
  .then(response => {
      const newdata = response.data.map((d,index)=> {d.id = index; return d})
      console.log(newdata)
      setDataGlRows(newdata);
      setGlLoading(false);
  })
  .catch((error) => {
      console.log(error);
  })

  axios.get(config.server_url + '/pt/cvg')
  .then(response => {
      const newdata = response.data.map((d,index)=> {d.id = index; return d})
      console.log(newdata)
      setDataCvgRows(newdata);
      setCvgLoading(false);
  })
  .catch((error) => {
      console.log(error);
  })

  axios.get(config.server_url + '/pt/mo')
  .then(response => {
      const newdata = response.data.map((d,index)=> {d.id = index; return d})
      console.log(newdata)
      setDataMoRows(newdata);
      setMoLoading(false);
  })
  .catch((error) => {
      console.log(error);
  })

  axios.get(config.server_url + '/pt/end')
  .then(response => {
      const newdata = response.data.map((d,index)=> {d.id = index; return d})
      console.log(newdata)
      setDataEndRows(newdata);
      setEndLoading(false);
  })
  .catch((error) => {
      console.log(error);
  })

  axios.get(config.server_url + '/pt/bn')
  .then(response => {
      const newdata = response.data.map((d,index)=> {d.id = index; return d})
      console.log(newdata)
      setDataBnRows(newdata);
      setBnLoading(false);
  })
  .catch((error) => {
      console.log(error);
  })

  axios.get(config.server_url + '/pt/add')
  .then(response => {
      const newdata = response.data.map((d,index)=> {d.id = index; return d})
      console.log(newdata)
      setDataAddRows(newdata);
      setAddLoading(false);
  })
  .catch((error) => {
      console.log(error);
  })

}, []);

const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  const failure = (reason) => {
    alert('Failed to edit policy id.' + ' Reason: ' + reason);
  }

  const renderLoadingMessage = () => <div className={classes.cent}><CircularProgress color="primary" /></div>;
  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <div className={classes.root}>
        <div className={classes.coldiv}>
      <AppBar className={classes.col} position="static">
        <Tabs centered value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab className={classes.size} label="Policy Header" {...a11yProps(0)} />
          <Tab className={classes.size} label="Addresses" {...a11yProps(1)} />
          <Tab className={classes.size} label="Current Coverage" {...a11yProps(2)} />
          <Tab className={classes.size} label="Policy Blanket" {...a11yProps(3)} />
          <Tab className={classes.size} label="Building" {...a11yProps(4)} />
          <Tab className={classes.size} label="Misc Outdoor" {...a11yProps(5)} />
          <Tab className={classes.size} label="General Liability" {...a11yProps(6)} />
          <Tab className={classes.size} label="Endorsements" {...a11yProps(7)} />
        </Tabs>
      </AppBar>
      </div>
      <TabPanel value={value} index={0}>
      <div>
        {loading? renderLoadingMessage():<PolicyHD data={dataRows}/>}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
        {loadingAdd? renderLoadingMessage():<PolicyADD data={dataAddRows} />}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div>
        {loadingCvg? renderLoadingMessage():<PolicyCVG data={dataCvgRows} />}
        </div>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <div>
        {loadingBn? renderLoadingMessage():<PolicyBN data={dataBnRows} />}
        </div>
      </TabPanel>

      <TabPanel value={value} index={4}>
        <div>
        {loadingCp? renderLoadingMessage():<PolicyBD data={dataCpRows} />}
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div>
        {loadingMo? renderLoadingMessage():<PolicyMO data={dataMoRows} />}
        </div>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <div>
        {loadingGl? renderLoadingMessage():<PolicyGL data={dataGlRows} />}
        </div>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <div>
        {loadingEnd? renderLoadingMessage():<PolicyEND data={dataEndRows} />}
        </div>
      </TabPanel>
      
    </div>
    </ThemeProvider>
  );
}
 