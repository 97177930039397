import React, { useState, useEffect } from 'react';
import AppBar from '../components/AppBar';
import Video from '../components/Video';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import { classnames } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  root:{
    '&:hover': {
      backgroundColor: '#4fc3f7',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
  },
button: {
backgroundColor: '#F6FBFE',
fontFamily:'Barlow, sans-serif',
border: 'none',
color: 'black',
padding: '4px 16px',
textAlign: 'center',
textDecoration: 'none',
display: 'inline-block',
fontSize: '16px',
margin: '8px 8px',
cursor: 'pointer',
borderRadius: '8px',
outline: 0,
fontWeight:'bold',
},
}));

const Home = () => {
  const classes=useStyles();
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getInitials = () => {
    let name = userInfo ? userInfo.name : null;
    if (!name) {
      return '';
    }
    let initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return initials;
  }

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const login = async () => {
    history.push('/login');
  };

  const logout = async () => oktaAuth.signOut();

  if (authState.isPending) {
    return (
      <div>Loading...</div>
    );
  }

  const button = authState.isAuthenticated ?
    <div><Button aria-controls="logout-menu" aria-haspopup="true" onClick={handleClick}>
      <Avatar style={{backgroundColor:'#316680', fontSize:'1.2em'}}>{getInitials()}</Avatar>
    </Button>
      <Menu
        id="logout-menu"
        anchorEl={anchorEl}
        aria-haspopup="true"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem >{userInfo ? userInfo.email : ""}</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
    :
    <Button className={clsx(classes.root,classes.button)} variant="contained" color="primary" disableElevation onClick={login}>Login</Button>;

  return (
    <>
      <AppBar LoginBtn={button} />
      <Video />
    </>
  );
}

export default Home;