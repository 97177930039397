import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from '../components/GenericDataGrid';
import AppBar from './AppBar';
import config from "./../config"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'10px',
    marginBottom:'45px'
},
root: {
  marginTop:'40px',
  '& .super--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
    
  },
  '& .super--cell2': {
    color: 'black',
    textAlign:'center'
    
  },
  '& .super--cell3': {
    color: 'black',
    fontWeight: '600',
    textAlign:'center',
    
  },
  '& .super4--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
  },
  '& .super--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)',
    fontWeight: 'bold',
    flexWrap:'wrap',
    alignItems:'center',
    textAlign:'center',
  },
}
}));

const HomePage = ({data}) => {
  const classes = useStyles();

  const [policies, setPolicies] = useState([]);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
  });
  
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell',
  };
  const usdPrice2 = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell3',
  };
  const usdPrice4 = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super4--cell',
  };

  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <GenericDataGrid 
      dataRows={data}
      ID = {'id'}
      ht={800}
      columns={[
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ITERATION_NUMBER', headerName: 'Iteration Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BLANKET_1_IND', headerName: 'Blanket 1 Indicator', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BLANKET_2_IND', headerName: 'Blanket 2 Indicator', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ALUMINUM_WIRING', headerName: 'Aluminum Wiring', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'AUTOMATIC_SPRINKLER', headerName: 'Automatic Sprinkler', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'BUILDING_NAME', headerName: 'Building Name', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BUILDING_NUMBER', headerName: 'Building Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'CONSTRUCTION_TYPE', headerName: 'Construction Type',cellClassName: 'super--cell2',headerAlign: 'center',type:'number', width: 170},
        { field: 'ELECTRICAL_LAST_UPDATED', headerName: 'Electrical Last Updated', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'NUM_STORIES', headerName: 'Number of Stories', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PLUMBING_LAST_UPDATED', headerName: 'Plumbing Last Updated', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'POLYBUTYLENE_PIPING', headerName: 'Polybutylene Piping', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ROOF_LAST_UPDATED', headerName: 'Roof Last Updated', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'ROOF_TYPE', headerName: 'Roof Type', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'TOTAL_BUILDING_AREA', headerName: 'Total Building Area', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'WATCHMEN_SECURITY_SERVICE', headerName: 'Watchmen Security Service', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'YEAR_BUILT', headerName: 'Year Built', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'RENTAL_PERIOD', headerName: 'Rental Period', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'RENTALS', headerName: 'Rentals', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BUILDING_AMOUNT_OF_INSURANCE', headerName: 'Building Amount of Insurance', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'BUILDING_COINSURANCE_PERCENTAGE', headerName: 'Building Coinsurance Percentage', width: 190,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BUILDING_DEDUCTIBLE', headerName: 'Building Deductible', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'BUILDING_VALUATION_TYPE', headerName: 'Building Valuation Type', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CLASS_CODE', headerName: 'Class Code', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CLASSIFICATION', headerName: 'Classification', width: 170,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ICE_DAMMING_DEDUCTIBLE', headerName: 'Ice Damming Deductible', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'LOCATION_NUMBER', headerName: 'Location Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ARMED_SECURITY_GUARDS', headerName: 'Armed Security Guards', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CONTRACTOR_GL_REQUIRED', headerName: 'Contractor GL Required', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'HO_6_POLICY', headerName: 'HO 6 Policy', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UNOCCUPIED_UNIT_HEAT', headerName: 'Unoccupied Unit Heat', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'NUMBER_OF_UNITS', headerName: 'Number of Units', width: 160,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ASSOC_BY_LAWS_COVERAGE', headerName: 'Assoc By Laws Covg', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
    ]}
    pageSize={100}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

export default HomePage;