import React from 'react';
import styles from './Video.css';

function Video() {
  return (<>
  <div className='hero-container'>
      <div className='hero-container'>
        <h1>LIO INSURANCE</h1>
        <p>We make insurance simple.</p>
      </div>
  </div>
  </>);
}

export default Video;