import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from '../components/GenericDataGrid';
import AppBar from './AppBar';
import config from "./../config"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'10px',
    marginBottom:'45px'
},
root: {
  marginTop:'40px',
  '& .super--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
    
  },
  '& .super--cell2': {
    color: 'black',
    textAlign:'center'
    
  },
  '& .super--cell3': {
    color: 'black',
    fontWeight: '600',
    textAlign:'center',
    
  },
  '& .super4--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
  },
  '& .super--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)',
    fontWeight: 'bold',
    flexWrap:'wrap',
    alignItems:'center',
    textAlign:'center',
  },
}
}));

const HomePage = ({data}) => {
  const classes = useStyles();

  const [policies, setPolicies] = useState([]);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
  });
  
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell',
  };
  const usdPrice2 = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell3',
  };
  const usdPrice4 = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super4--cell',
  };

  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <GenericDataGrid 
      dataRows={data}
      ID = {'id'}
      ht={800}
      columns={[
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BLANKET_NUMBER_1', headerName: 'Blanket Number 1', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BLANKET_NUMBER_2', headerName: 'Blanket Number 2', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BLANKET_PROPERTY_COINSURANCE_1', headerName: 'Blanket Property Coinsurance 1', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'BLANKET_PROPERTY_COINSURANCE_2', headerName: 'Blanket Property Coinsurance 2', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'PROPERTY_BLANKET_DESCRIPTION_1', headerName: 'Property Blanket Description 1', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PROPERTY_BLANKET_DESCRIPTION_2', headerName: 'Property Blanket Description 2', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PROPERTY_BLANKET_LIMIT_1', headerName: 'Property Blanket Limit 1', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'PROPERTY_BLANKET_LIMIT_2', headerName: 'Property Blanket Limit 2', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'PROPERTY_BLANKET_TYPE_1', headerName: 'Property Blanket Type 1',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'PROPERTY_BLANKET_TYPE_2', headerName: 'Property Blanket Type 2', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
    ]}
    pageSize={100}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

export default HomePage;