import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from './GenericDataGrid';
import AppBar from './AppBar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import GoogleMapReact from 'google-map-react';
import { GoogleMap, LoadScript,Marker } from '@react-google-maps/api';
import config from "./../config"
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    
  
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'3px'
},

textleft:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.4rem',
    textAlign:'center',
    marginTop:'0px',
    marginBottom:'25px',
},

load:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
},
formField:{
    display:'flex',
    justifyContent:'space-between',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
button: {
    backgroundColor: 'black',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'white',
    padding: '12px 35px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '10px 0px 0px 0px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
        color: "#4fc3f7",
        boxShadow: '4px 4px 4px grey',
     },
  },
  feature: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:'30px',
    marginBottom:'0px'
  },
  fea:{
    display:'flex',
      justifyContent:'center',
      paddingTop:'10px'
  },
  algn:{
      display:'flex',
    flexDirection:'row',
    justifyContent:'space-around',
  },
  col :{
    flex: '1', 
    padding: '1em',
    border: 'solid',
    borderStyle:'solid',
    outlineStyle:'solid',
    borderColor:'#4fc3f7',
    padding:'15px',
    boxShadow: '6px 6px 5px grey',
  },
  col2 :{
    flex: '2', 
    padding: '1em',
    justifyContent:'center',
    flexDirection:'row',
    alignItems:'center',
    paddingLeft:'160px',
  },
  fk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:'50px',
  borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
  fontSize:'1.2rem',
      fontWeight: 'bold',
      marginTop:100
},
center:{display: 'flex',
    justifyContent:'center'
},
bor:{
    borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  boxShadow: '6px 6px 5px grey',
},
cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'150px',
},
terror:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    textAlign:'center'
},
}));

const HomePage = () => {
const [policies, setPolicies] = useState([]);
const [loading, setLoading] = useState(true);
const [shouldRefresh, setShouldRefresh] = useState(false);
const [customer, setCustomer] = useState([]);
const classes = useStyles();
const [GeoData, setGeoData] = useState([{StAdd1: '',StAdd2: '',City: '',State: '',Zipcode: ''}])
const [latitude, setLatitude] = useState(37.774)
const [longitude, setlongitude] = useState(-122.419)
const [isSubmit, setIsSubmit] = useState(true)


const [data2, setData2] = useState([{
    bgIITerritory:{id: "", value: ""},terrorismTerritory:{id: "", value: ""},district:{id: "", value: ""},
    bgITerritory: {id: "", value: ""},city: "",county: "",cpTerritory: {id: "", value: ""},distanceToCoast: "",
    freezeScore: {id: "", value: ""},freezeScoreid:{id: "", value: ""},
    hailScore: {id: "", value: ""},latitude: "",longitude: "",ppc: {id: "", value: ""},
    sToRMi: {id: "", value: ""},specialTerritory: {id: "", value: ""},state: "",
    straightLineWindScore: {id: "", value: ""},street1: "",
    tornadoScore: {id: "", value: ""},premOpsTerritory: {id: "", value: ""},
    wildfireScore: {id: "", value: ""},
    windPoolScore: {id: "", value: ""},
    zipcode: "",precision:"",rawFreezeScore:""}])
    console.log(data2)

useEffect( () => {
    axios.get(config.server_url + '/pl/customer')
    .then(response => {
        setPolicies(response.data);
        setLoading(false);
    })
    .catch((error) => {
        console.log(error);
    })
}, [shouldRefresh]);

const onChangeData=(e) => {
    e.preventDefault();
    console.log(e.target.value);
    setCustomer(e.target.value);
    const filRows = policies.filter(row => row.CUSTOMER_ID==e.target.value )
    console.log(filRows)
    const newData = [{StAdd1:filRows[0].STREET_ADDR ,StAdd2: '',City: filRows[0].CITY ,State: filRows[0].STATE,Zipcode: filRows[0].ZIP.toString()}]
    console.log(newData)
    setGeoData(newData)
    console.log(customer);
}

const [dat,setDat] = useState({center: {
    lat: 59.95,
    lng: 30.33
  },
  zoom: 11
}
)
const AnyReactComponent = ({ text }) => <div>{text}</div>;


const mapContainerStyle = {
    height: "450px",
    width: "100%",
    
  }
  
  const center = {
    lat: latitude,
    lng: longitude
  }
  
  const position = {
    lat: latitude,
    lng: longitude
  }
  
  const onLoad = marker => {
    console.log('marker: ', marker)
  }

const onChangeFormData = (e, fieldName) => {
    const newData = [...GeoData]
    newData[0] = {
        ...newData[0],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setGeoData(newData)
  }

  const failure = (reason) => {
    alert('Failed to retrieve Data!' +  ' Reason: ' + reason);
  }
  
  const onSubmit = (event) => {
    event.preventDefault();
    {console.log('submit!!')}
    setIsSubmit(false)
    const geoadd = {
        
        StAdd1:GeoData[0].StAdd1,
        StAdd2:GeoData[0].StAdd2,
        City:GeoData[0].City,
        State:GeoData[0].State,
        Zipcode:GeoData[0].Zipcode,
    }
    console.log(geoadd);

    axios.post(config.server_url + '/geo/', geoadd)
            .then(res => {
                console.log(res.data)
                if(res && res.status === 200){
                    console.log(res.data)
                    setData2(res.data);
                    setLatitude(parseFloat(res.data[0].latitude));
                    setlongitude(parseFloat(res.data[0].longitude));
                    setIsSubmit(true)
                // alert('Successful!');
                //   window.location = '/wb/pol'
                }
            }, rejection => {
                setIsSubmit(true)
                failure('Request rejected!');
                window.location = '/wb/gc'
            }).catch(error => {
                
                setIsSubmit(true)
                failure('Error sending request due to invalid address.');
                window.location = '/wb/gc'
            });
            
}
const renderCustList = () => (
    <div>  
                    <FormControl size={'small'} variant='outlined'>
                        
                        <Select
                        native
                        value={customer}
                        onChange={(e) => onChangeData(e)}
                        ><option value=""> </option>
                            {policies.map(policy => {
                                return <option value={policy.CUSTOMER_ID}>{policy.CUSTOMER_ID}</option>
                            })}
                        </Select>
                </FormControl>      
  </div>
  );
const renderMap = () => ( 
                    <LoadScript  googleMapsApiKey="AIzaSyB_QpsLvMpwmzy_DBmsfThosaN2O9u9afs">
                    
                    <GoogleMap
                        id="marker-example"
                        mapContainerStyle={mapContainerStyle}
                        zoom={13}
                        center={center}
                    >
                        <Marker
                        onLoad={onLoad}
                        position={position}
                        />
                    </GoogleMap>
                    </LoadScript>);
        
const renderData = () => (<div className={classes.fk}> 
        <Grid container  direction='row'  spacing={2}>
                    <Grid className={classes.center} item xs={3}>
                        District: {data2[0].district.value}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        County: {data2[0].county}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Latitude: {data2[0].latitude}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Longitude: {data2[0].longitude}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Prem Ops Territory: {data2[0].premOpsTerritory.value}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        BGI Territory: {data2[0].bgITerritory.value}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        BGII Territory: {data2[0].bgIITerritory.value}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        CP Territory: {data2[0].cpTerritory.value}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Special Territory: {data2[0].specialTerritory.value}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Distance to Coast: {data2[0].distanceToCoast}
                        </Grid>
                    <Grid className={classes.center} item xs={3}>
                        PPC: {data2[0].ppc.value}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Freeze Score: {data2[0].freezeScore.value}, ID:{data2[0].freezeScore.id}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Raw Freeze Score: {data2[0].rawFreezeScore}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Hail Score: {data2[0].hailScore.value}, ID:{data2[0].hailScore.id}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Storm Score: {data2[0].sToRMi.value}, ID:{data2[0].sToRMi.id}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        St Line Wind Score: {data2[0].straightLineWindScore.value}, ID:{data2[0].straightLineWindScore.id}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Tornado Score: {data2[0].tornadoScore.value}, ID:{data2[0].tornadoScore.id}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Wildfire Score: {data2[0].wildfireScore.value}, ID:{data2[0].wildfireScore.id}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Wind Pool Score: {data2[0].windPoolScore.value}, ID:{data2[0].windPoolScore.id}
                    </Grid>
                    <Grid className={classes.center} item xs={3}>
                        Precision: {data2[0].precision}
                    </Grid>
                    <Grid className={classes.terror} item xs={3}>
                        Terrorism Territory: {data2[0].terrorismTerritory.value}
                    </Grid>
                    </Grid>
     </div>);
// console.log(policies);

  const renderGeo = () => (
      <div>
    <div className={classes.algn}>
    <div className={classes.col}>
    <h5 className={classes.textleft}>Choose Policy or Enter Address Details:</h5>
    <form  id ="geo" onSubmit={(event) => onSubmit(event)}>
            <Grid className={classes.center} container  direction='column' spacing={2}>
            <Grid item xs={10}>
                <div className={classes.formField}> 
                    <label>Policy: </label>
                    {renderCustList()}
                    </div>
                </Grid>
                <Grid item xs={10}>
                <div className={classes.formField}> 
                    <label>Street Address 1: </label>
                    <TextField size ={'small'}
                        value={GeoData[0].StAdd1}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(e, 'StAdd1')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={10}>
                <div className={classes.formField}> 
                    <label>Street Address 2: </label>
                    <TextField size ={'small'}
                        value={GeoData[0].StAdd2}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(e, 'StAdd2')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={10}>
                <div className={classes.formField}> 
                    <label>City: </label>
                    <TextField size ={'small'}
                        value={GeoData[0].City}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(e, 'City')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={10}>
                <div className={classes.formField}> 
                    <label>State: </label>
                    <TextField size ={'small'}
                        value={GeoData[0].State}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(e, 'State')
                        }
                    />
                </div>
                </Grid>
                
                <Grid item xs={10}>
                <div className={classes.formField}> 
                    <label>Zipcode: </label>
                    <TextField size ={'small'}
                        type="number"
                        value={GeoData[0].Zipcode}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(e, 'Zipcode')
                        }
                    />
                </div>
                </Grid>
                </Grid>
                <div className={classes.fea}>
                <input form="geo" type="submit" value="Submit" className={classes.button} alignItems='center' />
                </div>
                </form>
                </div>
                <div className={classes.col2}>
                    <div className={classes.bor}>
                {renderMap()}</div>
                </div>
  
  </div>
            {console.log(isSubmit)}
            {isSubmit? renderData():<div className={classes.cent}><CircularProgress color="primary" /></div>}
  </div>
  
  );


  return (<>
    <h3 className={classes.title}>Geocode Analysis</h3>
   {
    renderGeo()
   }
</>);
}

export default HomePage;