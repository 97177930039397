import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useEffect, useState } from 'react';
import axios from 'axios';
import PlLevel from './PlLevel'
import CpLevel from './CpLevel'
import GlLevel from './CpLevel'
import Bench from './Bench'
import { Link } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import config from "./../config"
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';


let theme = createMuiTheme({
    palette: {
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    typography: {
      fontFamily: 'Barlow, sans-serif',
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
        fontFamily:'Barlow, sans-serif',
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });
  
  theme = {
    ...theme,
    overrides: {
      MuiDrawer: {
        paper: {
          backgroundColor: 'black',
        },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
      MuiTabs: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: '#4fc3f7',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: theme.spacing(1),
        },
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: 4,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#404854',
        },
      },
      MuiListItemText: {
        primary: {fontSize: 25,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      MuiListItemIcon: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
      MuiAvatar: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({

    font:{
        fontFamily: 'Barlow, sans-serif',
        fontSize: '1.1rem',
        textDecoration: 'none',
        paddingTop:'10px',
        color:'black',
        fontWeight:'bold',
        paddingBottom:'0px',
    },
    col:{
        backgroundColor:'black',
    },
    size:{
        fontSize:'1.1rem',
        display:'inline-block'
    },
  root: {
    flexGrow: 1,
    
  },
  title:{
      
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    paddingTop:'0px',
    paddingBottom:'0px',
    marginTop: '40px',
    marginBottom: '0px',
},
load:{
  fontFamily:'Barlow, sans-serif',
  fontSize:'1.6rem',
  textAlign:'center',
},
button: {
  backgroundColor: '#F6FBFE',
  fontFamily:'Barlow, sans-serif',
  border: 'none',
  color: 'black',
  padding: '8px 8px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  margin: '8px 8px',
  cursor: 'pointer',
  borderRadius: '8px',
  outline: 0,
  fontWeight:'bold',
  '&:hover': {
    background: "#4fc3f7",
 },
},
agn:{
    marginLeft: 'auto',
    marginRight: '0',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    paddingRight:'20px',
},
cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'150px',
},
hov:{
  color:"white",
  fontSize:"2.5rem",
  fontWeight:'bold',
  '&:hover': {
    color: "#4fc3f7",
},
},
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [PlData, setPlData] = useState([]);
  const [CpData, setCpData] = useState([]);
  const [GlData, setGlData] = useState([]);
  const [isSubmit, setisSubmit] = useState(false);
//   console.log('id=', props.match.params.id);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    console.log('props.match.params.id', props.match.params.id);
    axios.get(config.server_url + '/pl/' + props.match.params.id)
    .then(response => {
        console.log('response.data: ', response.data[0]);
        setPlData(response.data)
        setisSubmit(true)
    })
    .catch(function (error) {
      console.log(error);
    })

    // console.log('props.match.params.id', props.match.params.id);
    axios.get(config.server_url + '/policy/' + props.match.params.id)
    .then(response => {
        // console.log('response.data[0]', response.data);
        setCpData(response.data)
    })
    .catch(function (error) {
      console.log(error);
    })

    // console.log('props.match.params.id', props.match.params.id);
    axios.get(config.server_url + '/gl/' + props.match.params.id)
    .then(response => {
        // console.log('response.data[0]', response.data);
        setGlData(response.data)
    })
    .catch(function (error) {
      console.log(error);
    })

  }, [props.match.params.id])

  const onChangeFormPlData = (index, e, fieldName) => {
    const newData = [...PlData]
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setPlData(newData)
  }

  const findCpIndex = (data, targetId) => {
    let targetIndex = -1;
    if(Array.isArray(data)){
      data.forEach(
        (dataItem, index) => {
          if(dataItem.PROPERTY_DETAILS_KEY === targetId) {
            targetIndex = index;
          }
        });
    }
    return targetIndex;
  }

  const findGlIndex = (data, targetId) => {
    let targetIndex = -1;
    if(Array.isArray(data)){
      data.forEach(
        (dataItem, index) => {
          if(dataItem.GL_DETAILS_KEY === targetId) {
            targetIndex = index;
          }
        });
    }
    return targetIndex;
  }

  const onChangeFormCpData = (e, fieldName, targetPolicyId) => {
    const newData = [...CpData] //make a copy of cpData;
    const changePolicyAtIndex = findCpIndex(newData, targetPolicyId);
    console.debug('changePolicyAtIndex', changePolicyAtIndex)
    if(changePolicyAtIndex > -1) {
      newData[changePolicyAtIndex][fieldName] = e.target.value;
    }
    setCpData(newData)
  }

  const onChangeFormGlData = (e, fieldName, targetPolicyId) => {
    const newData = [...GlData] //make a copy of glData;
    const changePolicyAtIndex = findGlIndex(newData, targetPolicyId);
    console.debug('changePolicyAtIndex', changePolicyAtIndex)
    if(changePolicyAtIndex > -1) {
      if (fieldName==='CLASS_CODE') {
        console.log('Hi')
        switch(e.target.value.toString()) {
          case '10105':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Sales',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Sales'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '40072':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Each Beach',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Each Beach'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '41668':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Area',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Area'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '45524':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Each Lake or reservoir',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Each Lake or reservoir'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '46622':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Area',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Area'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '46671':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Each Park or Playground',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Each Park or Playground'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '48727':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Each Mile',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Each Mile'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '48925':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Each pool',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Each pool'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '61218':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Area',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Area'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '61225':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Area',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Area'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '62003':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Units',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Units'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '68500':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Units',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Units'
          }
          console.log(newData)
          setGlData(newData)
          break;
  
          case '68707':
            newData[changePolicyAtIndex] = {
              ...newData[changePolicyAtIndex],
            [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Area',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Area'
          }
          console.log(newData)
          setGlData(newData)
          break;
        }
      }
      newData[changePolicyAtIndex][fieldName] = e.target.value;
    }
    setGlData(newData)
  }

  const failure = (reason) => {
    alert('Failed to edit policy id: ' + props.match.params.id + ' Reason: ' + reason);
  }

  const failureRate = (reason) => {
    alert('Failed to rate policy id: ' + props.match.params.id + ' Reason: ' + reason);
  }


  const onSubmitPl = (event,index) => {
    setisSubmit(false)
    event.preventDefault();

    const policy = {
        
        CUSTOMER_ID: PlData[index].CUSTOMER_ID,
        STATE: PlData[index].STATE,
        COMPANY_AUTO_HIRED: PlData[index].COMPANY_AUTO_HIRED,
        COMPANY_GL_PREMIER_ENDORSEMENT: PlData[index].COMPANY_GL_PREMIER_ENDORSEMENT,
        COMPANY_LIMITATION_COVERAGE:PlData[index].COMPANY_LIMITATION_COVERAGE,
        COMPANY_TERRORISM:PlData[index].COMPANY_TERRORISM,
        EACH_OCCURRENCE_LIMIT_CD:PlData[index].EACH_OCCURRENCE_LIMIT_CD,
        GENERAL_AGGREGATE_LIMIT_CD:PlData[index].GENERAL_AGGREGATE_LIMIT_CD,
        PRODS_COMPLD_OPS_AGGREGATE_LIMIT_CD:PlData[index].PRODS_COMPLD_OPS_AGGREGATE_LIMIT_CD,
        COMPANY_DESIGNATED_LOCATION:PlData[index].COMPANY_DESIGNATED_LOCATION,
        COMPANY_EMPLOYEE_BENEFITS_LIABILITY:PlData[index].COMPANY_EMPLOYEE_BENEFITS_LIABILITY,
        COMPANY_NUMBER_ADDITIONAL_INSURED:PlData[index].COMPANY_NUMBER_ADDITIONAL_INSURED,
        TIV:PlData[index].TIV,
        TOTAL_BUILDING_AMT_OF_INSURANCE:PlData[index].TOTAL_BUILDING_AMT_OF_INSURANCE,
        BPP_LIMIT:PlData[index].BPP_LIMIT,
        BI_LIMIT:PlData[index].BI_LIMIT,
        CARRIER:PlData[index].CARRIER,
        BATCH:PlData[index].BATCH,
        STREET_ADDR:PlData[index].STREET_ADDR,
        CITY:PlData[index].CITY,
        ZIP:PlData[index].ZIP,
        COUNTY:PlData[index].COUNTY,
        PROGRAM:PlData[index].PROGRAM,
        POLICY_NUMBER:PlData[index].POLICY_NUMBER,
        EXPIRING_PREMIUM:PlData[index].EXPIRING_PREMIUM,
        EFF_DATE:PlData[index].EFF_DATE,
        EXP_DATE:PlData[index].EXP_DATE,
        PROPERTY_TYPE:PlData[index].PROPERTY_TYPE,
        COMPANY_PROP_PREMIER_ENDORSEMENT:PlData[index].COMPANY_PROP_PREMIER_ENDORSEMENT,
        COMPANY_MAINTENANCEFEES:PlData[index].COMPANY_MAINTENANCEFEES,
        COMPANY_WATERDAMAGE:PlData[index].COMPANY_WATERDAMAGE,
        COMPANY_ICEDAMMING:PlData[index].COMPANY_ICEDAMMING,
        COMPANY_EQUIPMENT_BREAKDOWN:PlData[index].COMPANY_EQUIPMENT_BREAKDOWN,
        COMPANY_NONSPRINKLERED:PlData[index].COMPANY_NONSPRINKLERED,
        COMPANY_ASSOCIATION_BYLAWS:PlData[index].COMPANY_ASSOCIATION_BYLAWS,
        IRPMBUILDINGFEATURES:PlData[index].IRPMBUILDINGFEATURES,
        IRPMEMPLOYEES:PlData[index].IRPMEMPLOYEES,
        IRPMLOCATION:PlData[index].IRPMLOCATION,
        IRPMMANAGEMENT:PlData[index].IRPMMANAGEMENT,
        IRPMPREMISESANDEQUIPMENT:PlData[index].IRPMPREMISESANDEQUIPMENT,
        IRPMPROTECTION:PlData[index].IRPMPROTECTION,
        SCHEDULERATINGLOCATION:PlData[index].SCHEDULERATINGLOCATION,
        SCHEDULERATINGCOOPERATION:PlData[index].SCHEDULERATINGCOOPERATION,
        SCHEDULERATINGCLASSIFICATION:PlData[index].SCHEDULERATINGCLASSIFICATION,
        SCHEDULERATINGEMPLOYEES:PlData[index].SCHEDULERATINGEMPLOYEES,
        SCHEDULERATINGEQUIPMENT:PlData[index].SCHEDULERATINGEQUIPMENT,
        SCHEDULERATINGPREMISES:PlData[index].SCHEDULERATINGPREMISES,

    }

    console.log(policy);

    axios.post(config.server_url + '/pl/update/' + PlData[index].POLICY_HEADER_KEY, policy)
          .then(res => {
            if(res && res.status === 200){
              setisSubmit(true)
              alert('Successfuly Edited!');
            //   window.location = '/wb/pol'
            }
          }, rejection => {
            setisSubmit(true)
            failure('Request rejected for Policy!');
          }).catch(error => {
            setisSubmit(true)
            failure('Error sending request.');
          });
    
  }

  const onSubmitCp = (event, changedPolicyId) => {
    setisSubmit(false)
    event.preventDefault();

    const changedPolicyAtIndex = findCpIndex(CpData, changedPolicyId);

    if(changedPolicyAtIndex > -1){
        const policy = {
          
          CUSTOMER_ID: CpData[changedPolicyAtIndex].CUSTOMER_ID,
          ITERATION_NUMBER: CpData[changedPolicyAtIndex].ITERATION_NUMBER,
          BUILDING_NUM: CpData[changedPolicyAtIndex].BUILDING_NUM,
          PREMISES_NUM: CpData[changedPolicyAtIndex].PREMISES_NUM,
          CLASS_CODE: CpData[changedPolicyAtIndex].CLASS_CODE,
          NUM_STORIES: CpData[changedPolicyAtIndex].NUM_STORIES,
          THEFT_DEDUCTIBLE: CpData[changedPolicyAtIndex].THEFT_DEDUCTIBLE,
          WINDHAIL_DEDUCTIBLE: CpData[changedPolicyAtIndex].WINDHAIL_DEDUCTIBLE,
          PROPERTY_DEDUCTIBLE: CpData[changedPolicyAtIndex].PROPERTY_DEDUCTIBLE,
          WINDSTORM_OR_HAIL_EXCLUDED: CpData[changedPolicyAtIndex].WINDSTORM_OR_HAIL_EXCLUDED,
          SEWER_PD: CpData[changedPolicyAtIndex].SEWER_PD,
          SEWER_BI: CpData[changedPolicyAtIndex].SEWER_BI,
          PROPERTY_LIMIT_OF_INSURANCE: CpData[changedPolicyAtIndex].PROPERTY_LIMIT_OF_INSURANCE,
          TOTAL_BUILDING_AREA:CpData[changedPolicyAtIndex].TOTAL_BUILDING_AREA,
          STATE: CpData[changedPolicyAtIndex].STATE,
          CITY: CpData[changedPolicyAtIndex].CITY,
          COUNTY:CpData[changedPolicyAtIndex].COUNTY,
          PPC: CpData[changedPolicyAtIndex].PPC,
          DISTRICT: CpData[changedPolicyAtIndex].DISTRICT,
          BGI_TERRITORY:CpData[changedPolicyAtIndex].BGI_TERRITORY,
          BGII_TERRITORY: CpData[changedPolicyAtIndex].BGII_TERRITORY,
          SPECIAL_TERRITORY: CpData[changedPolicyAtIndex].SPECIAL_TERRITORY,
          TERRORISM_TERRITORY: CpData[changedPolicyAtIndex].TERRORISM_TERRITORY,
          CONSTRUCTION_TYPE: CpData[changedPolicyAtIndex].CONSTRUCTION_TYPE,
          CONSTRUCTION_TYPE_TO_USE: CpData[changedPolicyAtIndex].CONSTRUCTION_TYPE_TO_USE,
          BI_LIMIT_OF_INSURANCE: CpData[changedPolicyAtIndex].BI_LIMIT_OF_INSURANCE,
          BPP_LIMIT_OF_INSURANCE: CpData[changedPolicyAtIndex].BPP_LIMIT_OF_INSURANCE
      }

      axios.post(config.server_url + '/policy/update/' + changedPolicyId, policy)
            .then(res => {
              if(res && res.status === 200){
                setisSubmit(true)
                alert('Successfuly Edited!');
              //   window.location = '/wb/pol'
              }
            }, rejection => {
              setisSubmit(true)
              failure('Request rejected for CP!');
            }).catch(error => {
              setisSubmit(true)
              failure('Error sending request.');
            });
    }
  }

  const onSubmitGl = (event,changedPolicyId) => {
    setisSubmit(false)
    event.preventDefault();

    const changedPolicyAtIndex = findGlIndex(GlData, changedPolicyId);

    if(changedPolicyAtIndex > -1){

        const policy = {
            
            CUSTOMER_ID: GlData[changedPolicyAtIndex].CUSTOMER_ID,
            STATE: GlData[changedPolicyAtIndex].STATE,
            PREMISES_OPERATIONS_TERRITORY: GlData[changedPolicyAtIndex].PREMISES_OPERATIONS_TERRITORY,
            PRODS_COMPLD_OPS_TERRITORY: GlData[changedPolicyAtIndex].PRODS_COMPLD_OPS_TERRITORY,
            ITERATION_NUMBER: GlData[changedPolicyAtIndex].ITERATION_NUMBER,
            CLASS_CODE: GlData[changedPolicyAtIndex].CLASS_CODE,
            PREM_OPS_EXPOSURE: GlData[changedPolicyAtIndex].PREM_OPS_EXPOSURE,
            PRODS_COMPLD_OPS_EXPOSURE: GlData[changedPolicyAtIndex].PRODS_COMPLD_OPS_EXPOSURE,
            PREM_OPS_PREMIUM_BASIS: GlData[changedPolicyAtIndex].PREM_OPS_PREMIUM_BASIS,
            PRODS_COMPLD_OPS_PREMIUM_BASIS: GlData[changedPolicyAtIndex].PRODS_COMPLD_OPS_PREMIUM_BASIS,
            PREM_OPS_BIPD_DEDUCTIBLE: GlData[changedPolicyAtIndex].PREM_OPS_BIPD_DEDUCTIBLE,
            PRODS_COMPLD_OPS_BIPD_DEDUCTIBLE: GlData[changedPolicyAtIndex].PRODS_COMPLD_OPS_BIPD_DEDUCTIBLE,
            PRODS_COMPLD_OPS_COV: GlData[changedPolicyAtIndex].PRODS_COMPLD_OPS_COV,
            OTHER_THAN_PRODS_COMPLD_OPS_COV: GlData[changedPolicyAtIndex].OTHER_THAN_PRODS_COMPLD_OPS_COV,
        }


        console.log(policy);

        axios.post(config.server_url + '/gl/update/' + changedPolicyId, policy)
            .then(res => {
                if(res && res.status === 200){
                  setisSubmit(true)
                alert('Successfuly Edited!');
                //   window.location = '/wb/pol'
                }
            }, rejection => {
              setisSubmit(true)
                failure('Request rejected for GL!');
            }).catch(error => {
              setisSubmit(true)
                failure('Error sending request.');
            });

        }
    
  }


  const handleClick = (event) => {
    setisSubmit(false)
    event.preventDefault();

    axios.get(config.server_url + '/pl/rate/' + props.match.params.id)
    .then(res => {
      if(res && res.status === 200){
        alert('Successfuly Rated!');}
        setisSubmit(true)
    },rejection => {
      setisSubmit(true)
        failureRate('Request rejected.');
    })
    .catch((error) => {
        console.log(error);
        setisSubmit(true)
        failureRate('Error sending request.');
    })
  }
  const renderLoadingMessage = () => <div className={classes.cent}><CircularProgress color="primary" /></div>;
    const renderPolicyRows = () => {
        return PlData.map((currentpolicy, index) => {
        return <PlLevel policy={currentpolicy} index={index} onSubmit={onSubmitPl} onChangeFormData={onChangeFormPlData} key={currentpolicy.POLICY_HEADER_KEY}/>;
        })
}

console.log(PlData)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <div className={classes.root}>
        <div>
      <AppBar className={classes.col} position="static">
        <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab className={classes.size} label="Policy Level" {...a11yProps(0)} />
          <Tab className={classes.size} label="Commercial Property" {...a11yProps(1)} />
          <Tab className={classes.size} label="General Liability" {...a11yProps(2)} />
          <div className={classes.agn}>
          <Link to={`/wb/cdv`}><ArrowBackIcon className={classes.hov} fontSize="large" color="primary" /></Link>
          </div>
          <div >
          <input className={classes.button} type="button" value="Rate Policy" onClick={(event) =>  handleClick(event)} /></div>
        </Tabs>
      </AppBar>
      </div>
      <h3 className={classes.title}>Policy Details for Customer: {props.match.params.id}</h3>
      <TabPanel value={value} index={0}>
      <div>
        {isSubmit ? renderPolicyRows():renderLoadingMessage()}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
        {isSubmit? <Bench
          benchType={'CP'}
          ID={'PROPERTY_DETAILS_KEY'}
          data={CpData}
          customerId={props.match.params.id}
          onChangeFormData={onChangeFormCpData}
          onSubmit={onSubmitCp}/> :<div className={classes.cent}><CircularProgress color="primary" /></div>
        }
        
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
      {isSubmit? 
        <Bench
          benchType={'GL'}
          ID={'GL_DETAILS_KEY'}
          data={GlData}
          customerId={props.match.params.id}
          onChangeFormData={onChangeFormGlData}
          onSubmit={onSubmitGl}/>:<div className={classes.cent}><CircularProgress color="primary" /></div>}
      </TabPanel>
    </div>
    </ThemeProvider>
  );
}
