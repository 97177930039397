import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from '../components/GenericDataGrid';
import AppBar from './AppBar';
import config from "./../config"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'10px',
    marginBottom:'45px'
},
root: {
  marginTop:'40px',
  '& .super--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
    
  },
  '& .super--cell2': {
    color: 'black',
    textAlign:'center'
    
  },
  '& .super--cell3': {
    color: 'black',
    fontWeight: '600',
    textAlign:'center',
    
  },
  '& .super4--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
  },
  '& .super--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)',
    fontWeight: 'bold',
    flexWrap:'wrap',
    alignItems:'center',
    textAlign:'center',
  },
}
}));

const HomePage = ({data}) => {
  const classes = useStyles();

  const [policies, setPolicies] = useState([]);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
  });
  
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell',
  };
  const usdPrice2 = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell3',
  };
  const usdPrice4 = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super4--cell',
  };

  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <GenericDataGrid 
      dataRows={data}
      ID = {'id'}
      ht={800}
      columns={[
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'APPROVED', headerName: 'Approved', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMM_CONDOS', headerName: 'Comm Condos', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CONDOTELS', headerName: 'Condotels', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'EQUESTRIAN_EXPOSURES', headerName: 'Equestrian Exposures', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'HUNTING_SHOOTING', headerName: 'Hunting Shooting', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'ICE_FISHING', headerName: 'Ice Fishing',cellClassName: 'super--cell2',headerAlign: 'center',type:'number', width: 130},
        { field: 'ICE_SKATING', headerName: 'Ice Skating', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'JET_SKIING', headerName: 'Jet Skiing', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'LAKE_OVER_100_ACRE', headerName: 'Lake over 100 Acre', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ON_PREM_GOLF', headerName: 'On Prem Golf', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'POOLS_DIVING_BOARDS', headerName: 'Pools Diving Boards', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'POOLS_SLIDES', headerName: 'Pools Slides', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'SNOW_SKIING', headerName: 'Snow Skiing', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'STATE_PARKS', headerName: 'State Parks', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'WATER_SKIING', headerName: 'Water Skiing', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'WOOD_DECK_GRILLING', headerName: 'Wood Deck Grilling', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CARRIER', headerName: 'Carrier', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'EFF_DATE', headerName: 'Eff Date', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'EXP_DATE', headerName: 'Exp Date', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'EXPIRING_GL_PREMIUM', headerName: 'Expiring GL Premium', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'EXPIRING_PREMIUM', headerName: 'Expiring Premium', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'EXPIRING_PROP_PREMIUM', headerName: 'Expiring Prop Premium', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'POLICY_TYPE', headerName: 'Policy Type', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PROPERTY_TYPE', headerName: 'Property Type', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'TAM_CUSTOMER_FULL_NAME', headerName: 'TAM Customer Full Name', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'MISCELLANEOUS_OUTDOOR_PROPERTY_TOTAL', headerName: 'Misc Outdoor Property Total',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2, width: 150 },
        { field: 'POLICY_NUMBER', headerName: 'Policy Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMENTS', headerName: 'Comments', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'MISC_EXPOSURES', headerName: 'Misc Exposures', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
    ]}
    pageSize={100}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

export default HomePage;