import React from 'react';
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, useHistory, Switch } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import "./main.css"
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import EditXml from "./routes/EditXml";
import EditStatus from "./components/EditStatus";

import WorkBench from "./components/WorkBench"
import AppBar from "./components/AppBar";
import Tab from "./components/tab";
import Home from "./routes/Home";
import Login from './components/Login';
import axios from 'axios';
import config from './config';

const oktaAuth = new OktaAuth(config.oidc);

axios.interceptors.request.use(function (config) {
  const userData = JSON.parse(localStorage.getItem('okta-token-storage'))
  const token = userData.accessToken.accessToken;
  // Do something before request is sent
  config.headers['Authorization'] = 'Bearer ' + token;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

const App = () => {

  const history = useHistory(); // example from react-router

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history ? history.push('/login') : window.location.href = "/login";
  };

  return (
    <Router>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
      >
        <Switch>
          <Route path="/" exact component={Home} />
          <SecureRoute path="/editxml/:id" component={EditXml} />
          <SecureRoute path="/editsts/:id" component={EditStatus} />
          <SecureRoute path="/wb" component={WorkBench} />
          <SecureRoute path="/tab/:id" component={Tab} />
          <Route path="/login/callback" component={LoginCallback} />
          <Route path="/login" component={Login} />
        </Switch>
      </Security>
    </Router>
  );
}

export default App;