import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from './GenericDataGrid';
import AppBar from './AppBar';
import config from "../config"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'10px',
    marginBottom:'45px'
},
root: {
  marginTop:'40px',
  '& .super--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
    
  },
  '& .super--cell2': {
    color: 'black',
    textAlign:'center'
    
  },
  '& .super--cell3': {
    color: 'black',
    fontWeight: '600',
    textAlign:'center',
    
  },
  '& .super4--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
  },
  '& .super--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)',
    fontWeight: 'bold',
    flexWrap:'wrap',
    alignItems:'center',
    textAlign:'center',
  },
}
}));

const HomePage = ({data}) => {
  const classes = useStyles();
  console.log(data)

  const [policies, setPolicies] = useState([]);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
  });
  
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell',
  };
  const usdPrice2 = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell3',
  };
  const usdPrice4 = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super4--cell',
  };

  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <GenericDataGrid 
      dataRows={data}
      ID = {'id'}
      ht={800}
      columns={[
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMPANYPROPPREMIERENDORSEMENT', headerName: 'Comp Prop Premier Endorsement', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BUSINESS_INCOME_WITH_EXTRA_EXPENSE_LIMIT', headerName: 'BI with Extra Expense Limit', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',type:'number',...usdPrice2 },
        { field: 'BUSINESS_INCOMEEXTRA_EXPENSE_INDICATOR', headerName: 'BI/EE Indicator', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ORDINANCE_OR_LAW_COVERAGE_A_INDICATOR', headerName: 'Ordinance or Law Covg A Indicator', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'ORDINANCE_OR_LAW_COVERAGE_B_LIMIT', headerName: 'Ordinance or Law Covg B Limit',cellClassName: 'super--cell2',headerAlign: 'center',type:'number',...usdPrice2,width: 170},
        { field: 'ORDINANCE_OR_LAW_COVERAGE_C_LIMIT', headerName: 'Ordinance or Law Covg C Limit', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'PERSONAL_PROPERTY_INDICATOR', headerName: 'Personal Property Indicator', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PERSONAL_PROPERTY_LIMIT', headerName: 'Personal Property Limit', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'AUTO_TYPE', headerName: 'Auto Type', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GL_EACH_OCCURRENCE_LIMIT_LIST', headerName: 'GL Occurrence Limit', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2},
        { field: 'GL_ENHANCEMENT_IND', headerName: 'GL Enhancement Ind', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GL_GENERAL_AGGREGATE_LIMIT_LIST', headerName: 'GL General Agg Limit', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' ,...usdPrice2},
        { field: 'GL_MEDICAL_EXPENSE_LIMIT_LIST', headerName: 'GL Medical Expense Limit', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' ,...usdPrice2},
        { field: 'GL_PERSONAL_ADVERTISING_INJURY_LIABILITY_LIMIT_LIST', headerName: 'GL Personal Advt Injury Liability', width: 170,cellClassName: 'super--cell2',headerAlign: 'center' ,...usdPrice2},
        { field: 'GL_PRODUCTS_COMPLETED_OPERATIONS_AGGREGATE', headerName: 'GL Products Completed Ops Aggregate', cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2, width: 200 },
        { field: 'POLICYGL_GL_DAMAGE_TO_RENTED_PREMISES_COV_LIMIT', headerName: 'GL Damage to Rented Premises Covg Limit',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2, width: 200 },
        { field: 'TERRORISM_IND', headerName: 'Terrorism Indicator', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
    ]}
    pageSize={100}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

export default HomePage;